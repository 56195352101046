require("@rails/ujs").start();
require("turbolinks").start();
require.context("../images", true);

import { Carousel } from "bootstrap";
import { jarallax } from "jarallax";
import GLightbox from "glightbox";
import Alpine from "alpinejs";
import TomSelect from "tom-select";
import Glide from "@glidejs/glide";
import lozad from "lozad";

import "website.scss";
import "fa_icons";
import "glightbox/dist/css/glightbox.css";
import "tom-select/dist/css/tom-select.bootstrap5.css";
import "@glidejs/glide/dist/css/glide.core";

function initFooterMap() {
  const aktalLatLng = { lat: 24.86135876723226, lng: 46.64352690286955 };
  const map = new google.maps.Map(document.getElementById("footer-map"), {
    center: aktalLatLng,
    zoom: 13,
  });
  const _marker = new google.maps.Marker({
    position: aktalLatLng,
    map: map,
  });
}

window.addEventListener("load", (_) => {
  const observer = lozad();
  observer.observe();
  window.TomSelect = TomSelect;
  window.Glide = Glide;
  Alpine.start();
  jarallax(document.querySelectorAll(".jarallax"));
  Array.from(document.querySelectorAll(".carousel")).forEach(
    (node) => new Carousel(node)
  );
  const lightbox = GLightbox();

  initFooterMap();
});
